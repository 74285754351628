.custom-card {
    /* Your card styles here */
    color: #fff;
        /* Text color */
       
        /* Padding around the content */
        text-align: center;
        /* Center-align the text */
        position: relative;
        /* Relative positioning for z-index */
        z-index: 1;
        /* Bring content to the front */
}

.card-image {
    /* Your card image styles here */
    position: relative;
    overflow: hidden;
}

.image {
    /* Your image styles here */
    width: 100%;
    height: 200;
    transition: transform 0.2s ease-in-out;
}

.overlay {
    /* Your overlay styles here */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay-inner {
    /* Your overlay inner styles here */
    text-align: center;
}

.card-content {
    /* Your card content styles here */
    color: #fff;
}